import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Auth, Cache } from 'aws-amplify';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';

import Avatar from '@/common/Avatar';
import { doToggleTheme } from '@/shared/user/actions';
import RealtimeSubscriptions from "@/common/RealtimeSubscriptions";
import {
    canAdmin,
    canManageUsers,
    canManageCommunicationGroups,
    canViewReports,
    canManageRules,
    canManageSurveys,
    canManageTasks,
    canManageArticles,
    canViewConversations,
    canParticipateConversations,
    canViewFeedbackBoard, canViewActivity, canManageSatisfaction, hasAuthoritiesLoaded,
    canBatchFeature,
    isPatientsEnabled,
    isCandidateCommsEnabled,
    isPlatformLoginEnabled, isTopNavEnabled
} from "@/shared/access";
import MasqWarning from "@/common/MasqWarning";
import { FEDERATED_PATH } from "@/common/CacheKeys";
import {EidAuthContext} from '../eid/EIDAuthContext';
import {createSettingsConfig} from "../topnav/SettingsConfig";
import {createHelpConfig} from "../topnav/HelpConfig";
import {createSubNavConfig} from "../topnav/SubNavConfig";
import {createProfileConfig} from "../topnav/ProfileConfig";
import {getAuthorization} from "../setupAmplify";

const mapStateToProps = state => ({
    user: state.user,
    //...state
})

const mapDispatchToProps = dispatch => ({
    toggleThem: () => dispatch(doToggleTheme())
})

function LogoutIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z" />
        </SvgIcon>
    );
}

function NavItem(props) {
    var { itemClass, path, active, name, icon } = props;
    return (
        <Link to={path}>
            <div className={`navigationItem ${itemClass} ${active ? "selected" : ""}`}>
                {icon ??
                    <div className="icon"></div>
                }
                <div className="title">{name}</div>
            </div>
        </Link>
    )
}

const LANDING_PATHS = [
    {
        path: "/conversations",
        access: () => {
            return canViewConversations() || canParticipateConversations();
        }
    },
    {
        path: "/activity",
        access: () => {
            return canViewActivity();
        }
    },
    {
        path: "/tasks",
        access: () => {
            return canManageTasks();
        }
    }
]

function Sidebar(props) {
    var { location } = props;
    let userInfo = props?.user?.aboutMe?.user;
    const navbarRef = useRef(null);
    const { eidUser,eidUserSignOut } = useContext(EidAuthContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let currentPath = _.trimStart(location.pathname, "/");
    const [selectedItemId, setSelectedItemId] = useState("");
    const [keyPress, setKeyPress] = useState(false);
    const history = useHistory();
    const pathToItemIdMap = {
        'conversations': 'Conversations',
        'clients': 'Contacts',
        'activity': 'Activity',
        'tasks': 'Tasks',
        'positivity': 'Feedback',
        'candidates': 'Candidates',
        'measure': 'Measure',
        'datasources': 'Data Sources',
        'rules': 'Administrative',
        'survey': 'Administrative',
        'library': 'Administrative',
        'file-import': 'Administrative',
        'providers': 'Administrative',
        'services': 'Administrative'
    };

    const handleLogout = () => {
        let path = Cache.getItem(FEDERATED_PATH);
        Cache.clear();
        if (eidUser) {
            eidUserSignOut();
            return;
        }
        Auth.signOut()
            .then(data => {
                if (path) {
                    window.location = "/" + path + "/auth"
                } else {
                    window.location = "/auth";
                }
            })
            .catch(err => console.log(err));
    }
    const loadTopNavScript = (topNavUrl) => {
        const script = document.createElement('script');
        script.type = 'module';
        script.src = topNavUrl;
        script.onload = () => {
            navbarRef.current = document.querySelector('ce-neogov-top-navbar');
            setupNavbar();
        };
        script.onerror = () => console.error("Failed to load the topnav script.");
        document.body.appendChild(script);
    };

    useEffect(() => {
        if (isTopNavEnabled()) {
            if (customElements.get('ce-neogov-top-navbar')) {
                navbarRef.current = document.querySelector('ce-neogov-top-navbar');
                setupNavbar();
            } else {
                const topNavUrl = "https://awscdn.neogov.com/products/us/neogov-top-navbar/bundle/bundle.js";
                loadTopNavScript(topNavUrl);
            }
        }
    }, [userInfo]);
    const setupNavbar = async () => {
        if (navbarRef.current) {
            let tenantName = _.has(props, 'user.aboutMe.currentTenant') ? props?.user?.aboutMe?.currentTenant?.name : '';
            if (isPlatformLoginEnabled()) {
                const appSwitcherConfig = await fetchAndPrepareAppSwitcherConfig();
                navbarRef.current.appSwitcher.setAppSwitcher(appSwitcherConfig);
            }
            if (canManageUsers()) {
                navbarRef.current.settings.setSettings(createSettingsConfig(history));
            }
            if (canAdmin()) {
                navbarRef.current.help.setHelp(createHelpConfig(history));
            }
            navbarRef.current.profile.setProfile(createProfileConfig(userInfo, handleLogout, props.toggleThem, tenantName));
            navbarRef.current.productName = 'ENGAGE';
            navbarRef.current.neoLogo = {
                type: 'anchor',
                anchorHref: '/',
                onClick: (e) => {
                    history.push('/');
                    return {
                        preventOverlayClose: false
                    };
                }
            }
        }
    };

    useEffect(() => {
        if (isTopNavEnabled() && navbarRef.current) {
            const handleKeyPress = (event) => {
                if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
                    setSelectedItemId('');
                    setKeyPress(true);
                }
            };
            if (!selectedItemId && !keyPress) {
                const currentLocationPath = currentPath.split('/').pop();
                const itemId = pathToItemIdMap[currentLocationPath];
                if (itemId) setSelectedItemId(itemId);
            }
            document.addEventListener("keydown", handleKeyPress);
            const subNavConfig = createSubNavConfig(history, userInfo, setSelectedItemId, selectedItemId);
            navbarRef.current.navigation.setNavigation(subNavConfig);

            return () => {
                document.removeEventListener("keydown", handleKeyPress);
            };
        }
    }, [userInfo,selectedItemId]);

    async function fetchAndPrepareAppSwitcherConfig() {
        const baseUrl = window.__RUNTIME_CONFIG__.EID_AUTHORITY_URL;
        const engageApp = window.__RUNTIME_CONFIG__.EID_PRODUCT_CODE;
        const url = `${baseUrl}accounts/products?callingApp=${engageApp}`;
        try {
            const token = await getAuthorization();
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            let configResult = {
                isEnabled: true,
                options: result.options.map((option) => ({
                    id: option.id,
                    clickable: {
                        type: 'anchor',
                        anchorHref: option.productUrl,
                        onClick: () => {
                            window.open(option.productUrl, '_blank');
                            return { preventOverlayClose: false };
                        }
                    },
                    categories: option.categories.map((category) => ({
                        name: category.name,
                        products: category.products.map((product) => ({
                            name: product.name,
                            clickable: {
                                type: 'anchor',
                                anchorHref: product.productUrl,
                                onClick: () => {
                                    window.open(product.productUrl, '_blank');
                                    return { preventOverlayClose: false };
                                }
                            }
                        })),
                    }))
                }))
            };
            const defaultOption = result.options.find(option => option.isDefault);
            if (defaultOption) {
                configResult.defaultOptionType = defaultOption.id;
            }
            return configResult;
        } catch (error) {
            console.error('Failed to fetch or process app switcher config:', error);
            return {
                isEnabled: false
            };
        }
    }

    // Taking a survey or starting a video chat should not show sidebar
    if (_.startsWith(currentPath, 'video-chat')|| _.startsWith(currentPath, 'feedback-public') || _.startsWith(currentPath, 'take-survey')
        || _.startsWith(currentPath, 'ehr') || _.startsWith(currentPath, 'applicant')) {
        return null;
    }

    // Sidebar shouldn't show unless logged in. account for signin page /auth and federated sign in page /:path/auth. Only redirect if authorities loaded.
    if (_.endsWith(currentPath, 'auth') && hasAuthoritiesLoaded()) {
        if (isTopNavEnabled()) {
            props.history.push("/");
        } else {
            let redirected = false;
            _.forEach(LANDING_PATHS, function (PTH) {
                if (PTH.access()) {
                    props.history.push(PTH.path);
                    redirected = true;
                    return false;
                }
            });
            if (!redirected) {
                props.history.push("/");
            }
        }
    }

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isActive = (path) => {
        let targetPath = _.trimStart(path, "/");
        let currentPath = _.trimStart(location.pathname, "/");
        if (targetPath === "") {
            return currentPath === "" || currentPath === "home";
        }
        let matches = _.startsWith(currentPath, targetPath);
        return matches;
    }

    if (props.user.aboutMeError) {
        handleLogout();
    }

    var backgroundImageUrl = null;
    if (_.has(props, 'user.aboutMe.user') && props.user.aboutMe.user.lightTheme) {
        backgroundImageUrl = '/powerdms-engage-FKA-logo.svg';
    }
    else {
        backgroundImageUrl = '/powerdms-engage-FKA-logo-white.svg';
    }

    let logo = (<div style={{
        backgroundImage: `url("${backgroundImageUrl}")`,
        backgroundSize: '140px 40px',
        width: '140px'
    }} className="logo"></div>);
    if (_.has(props, 'user.aboutMe.currentTenant') && props.user.aboutMe.currentTenant.logoFilename) {
        logo = (<div style={{
            backgroundImage: `url("/logos/${props.user.aboutMe.currentTenant.logoFilename}")`,
            backgroundSize: '140px 40px',
            width: '140px'
        }} className="logo"></div>);
    }

    let tasksSection = true;

    let masqWarning = null;
    if (_.has(props, 'user.aboutMe.currentTenant') && _.has(props, 'user.aboutMe.user.userType') && props.user.aboutMe.user.userType === "INTERNAL_SUPPORT") {
        masqWarning = <MasqWarning tenantName={props.user.aboutMe.currentTenant.name} />
    }

    if (_.has(props, 'user.aboutMe.user') && props.user.aboutMe.user.lightTheme) {
        document.body.classList.remove('prefersDarkTheme');
    }
    else {
        document.body.classList.add('prefersDarkTheme');
    }

    return (
        <div>{
            isTopNavEnabled() ?
                <>
                    <ce-neogov-top-navbar/>
                    {masqWarning && (
                        <div style={{ marginTop: '45px' }}>
                            {masqWarning}
                        </div>
                    )}
                </>  :
                (<div className="tabBar">
                    <div className="header">
                        {logo}
                        <div className="separator"/>
                    </div>
                    {masqWarning}
                    <div className="navigationItems">
                        <div className="navigationSection">
                            <NavItem itemClass={"onboarding"} path={"/"} name={"Get Started"} active={isActive("/")}/>
                            <div className="separator"/>
                        </div>

                        <div className="navigationSection sectionCare">
                            <div className="sectionTitle">Respond</div>
                            {
                                canViewConversations() || canParticipateConversations() ? (
                                    <NavItem itemClass={"conversations"} path={"/conversations"} name={"Conversations"}
                                             active={isActive("/conversations")}/>
                                ) : null
                            }
                            {
                                canManageTasks() && tasksSection ? (
                                    <NavItem itemClass={"tasks"} path={"/tasks"} name={"Tasks"}
                                             active={isActive("/tasks")}/>
                                ) : null
                            }
                            {
                                canViewConversations() || canParticipateConversations() ? (
                                    null
                                    // <NavItem itemClass={"map"} path={"/map"} name={"Map"} active={isActive("/map")} />
                                ) : null
                            }
                            <div className="separator"/>
                        </div>

                        {
                            canViewActivity() || canManageRules() || canManageSurveys() || canManageArticles() || canViewFeedbackBoard() || isCandidateCommsEnabled() ? (
                                <div className="navigationSection sectionEngage">
                                    <div className="sectionTitle">Engage</div>

                                    {
                                        canViewActivity() ? (
                                            <NavItem itemClass={"activity"} path={"/activity"} name={"Activity"}
                                                     active={isActive("/activity")}/>
                                        ) : null
                                    }
                                    {
                                        canManageRules() ? (
                                            <NavItem itemClass={"rules"} path={"/rules"} name={"Rules"}
                                                     active={isActive("/rules")}/>
                                        ) : null
                                    }
                                    {
                                        canManageSurveys() ? (
                                            <NavItem itemClass={"survey"} path={"/survey"} name={"Survey"}
                                                     active={isActive("/survey")}/>
                                        ) : null
                                    }
                                    {
                                        canManageArticles() ? (
                                            <NavItem itemClass={"articles"} path={"/library"} name={"Library"}
                                                     active={isActive("/library")}/>
                                        ) : null
                                    }
                                    {
                                        canViewFeedbackBoard() ? (
                                            <NavItem itemClass={"feedback"} path={"/positivity"} name={"Feedback"}
                                                     active={isActive("/positivity")}/>
                                        ) : null
                                    }
                                    {
                                        isCandidateCommsEnabled() ? (
                                            <NavItem itemClass={"Candidates"} path={"/candidates"} name={"Candidates"}
                                                     active={isActive("/candidates")}/>
                                        ) : null
                                    }
                                    <div className="separator"/>
                                </div>
                            ) : null
                        }
                        {
                            canViewReports() || canManageSatisfaction() ? (
                                <div className="navigationSection sectionMeasure">
                                    <div className="sectionTitle">Measure</div>
                                    {
                                        canViewReports() ? (
                                            <NavItem itemClass={"measure"} path={"/measure"} name={"Measure"}
                                                     active={isActive("/measure")}/>
                                        ) : null
                                    }
                                    {
                                        canManageSatisfaction() ? (
                                            <NavItem itemClass={"satisfaction"} path={"/satisfaction"}
                                                     name={"Satisfaction"} active={isActive("/satisfaction")}/>
                                        ) : null
                                    }
                                    <div className="separator"/>
                                </div>
                            ) : null
                        }

                        {
                            canManageUsers() || canManageCommunicationGroups() ? (
                                <div className="navigationSection sectionManage">
                                    <div className="sectionTitle">Manage</div>
                                    {
                                        canManageUsers() ? (
                                            <NavItem itemClass={"settings"} path={"/settings"} name={"Settings"}
                                                     active={isActive("/settings")}/>
                                        ) : null
                                    }
                                    {
                                        canManageUsers() && canBatchFeature() ? (
                                            <NavItem itemClass={"file-import"} path={"/file-import"}
                                                     name={"File Import"}
                                                     active={isActive("/file-import")}/>
                                        ) : null
                                    }
                                    {
                                        canManageUsers() ? (
                                            <NavItem itemClass={"clients"} path={"/clients"} name={"Contacts"}
                                                     active={isActive("/clients")}/>
                                        ) : null
                                    }
                                    {
                                        isPatientsEnabled() ? (
                                            <NavItem itemClass={"providers"} path={"/providers"} name={"Providers"}
                                                     active={isActive("/providers")}/>
                                        ) : null
                                    }

                                    {
                                        isPatientsEnabled() ? (
                                            <NavItem itemClass={"services"} path={"/services"} name={"Services"}
                                                     active={isActive("/services")}/>
                                        ) : null
                                    }

                                    <div className="separator"/>
                                </div>
                            ) : null
                        }

                        {
                            props.user?.aboutMe?.user?.userType === "INTERNAL_SUPPORT" ? (
                                <div className="navigationSection sectionSupport">
                                    <div className="sectionTitle">Support</div>

                                    <NavItem itemClass={"sources"} path={"/datasources"} name={"Data Sources"}
                                             active={isActive("/datasources")}/>

                                    <div className="separator"/>
                                </div>
                            ) : null
                        }

                        {
                            canAdmin() ? (
                                <div className="navigationSection sectionSupport">
                                    <NavItem icon={<HelpOutlineIcon color={"primary"}/>} itemClass={"sources"}
                                             path={"/help"} name={"Help"} active={isActive("/help")}/>
                                    <div className="separator"/>
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="bottomActions" aria-controls="simple-menu" aria-haspopup="true"
                         onClick={handleClick}>
                        <div className="customerDetails">
                            <Avatar bottom
                                    name={_.has(props, 'user.aboutMe.user') ? props.user.aboutMe.user.readableName : ""}
                                    size={36} textSizeRatio={2}/>
                            <div className="details">
                                <div
                                    className="fullName">{_.has(props, 'user.aboutMe.user') ? props.user.aboutMe.user.readableName : ""}</div>
                                <div
                                    className="clinicName">{_.has(props, 'user.aboutMe.currentTenant') ? props.user.aboutMe.currentTenant.name : ""}</div>
                            </div>
                        </div>
                    </div>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => {
                            props.toggleThem();
                            handleClose();
                        }}>
                            {
                                (_.has(props, 'user.aboutMe.user') && props.user.aboutMe.user.lightTheme) ?
                                    (
                                        <ListItemText primary={"Use Dark Theme"}/>
                                    ) : (
                                        <ListItemText primary={"Use Light Theme"}/>
                                    )
                            }

                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon><LogoutIcon/></ListItemIcon>
                            <ListItemText primary={"Logout"}/>
                        </MenuItem>
                    </Menu>)
                </div>)}
            {_.has(props, 'user.aboutMe.currentTenant') ? (
                <RealtimeSubscriptions tenantId={props.user.aboutMe.currentTenant.id}
                                       userId={props.user.aboutMe.user.id}/>) : null}
        </div>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));